"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateQRCodeIdentifier = exports.getQrcodeEnvironment = exports.QRCodeContentType = exports.getCharSafeFacilityName = void 0;
var getCharSafeFacilityName = function getCharSafeFacilityName(facilityName) {
    return facilityName.replace(/[^\w ]/g, '').replace(/\s+/g, '_');
};
exports.getCharSafeFacilityName = getCharSafeFacilityName;
exports.QRCodeContentType = function (QRCodeContentType) {
    QRCodeContentType["DOWNLOAD"] = "download";
    QRCodeContentType["WEB_BASED"] = "web based";
    QRCodeContentType["QR_CODE_VERIFIER"] = "qr code verifier";
    return QRCodeContentType;
}({});
var getQrcodeEnvironment = function getQrcodeEnvironment(_ref) {
    var configName = _ref.configName, facilityEnvironment = _ref.facilityEnvironment;
    if (facilityEnvironment === 'dev') {
        return "".concat(configName, "/").concat(facilityEnvironment);
    }
    return configName;
};
exports.getQrcodeEnvironment = getQrcodeEnvironment;
var generateQRCodeIdentifier = function generateQRCodeIdentifier(_ref2) {
    var type = _ref2.type, qrCodeId = _ref2.qrCodeId, configName = _ref2.configName, environment = _ref2.environment, payload = _ref2.payload;
    var qrcodeEnvironment = (0, exports.getQrcodeEnvironment)({
        configName: configName,
        facilityEnvironment: environment
    });
    switch (type) {
        case exports.QRCodeContentType.DOWNLOAD:
        case exports.QRCodeContentType.WEB_BASED:
            return JSON.stringify({
                id: qrCodeId,
                environment: qrcodeEnvironment
            });
        case exports.QRCodeContentType.QR_CODE_VERIFIER:
            return JSON.stringify({
                id: qrCodeId,
                environment: qrcodeEnvironment,
                trainingSeriesIndex: payload === null || payload === void 0 ? void 0 : payload.trainingSeriesIndex
            });
        default:
            throw new Error("Not supported. Type: ".concat(type));
    }
};
exports.generateQRCodeIdentifier = generateQRCodeIdentifier;
