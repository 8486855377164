"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSignupLinkForTeam = void 0;
var getSignupLinkForTeam = function getSignupLinkForTeam(data) {
    var serializedParams = Object.keys(data).map(function (key) {
        return "".concat(key, "=").concat(encodeURIComponent(data[key]));
    }).join('&');
    return "".concat(window.location.origin, "/signup/?").concat(serializedParams);
};
exports.getSignupLinkForTeam = getSignupLinkForTeam;
