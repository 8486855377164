"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerTraining = void 0;
const utils_1 = require("../utils");
var registerTraining = function registerTraining(payload) {
    return (0, utils_1.post)({
        endpoint: 'training-registrations',
        payload: payload
    });
};
exports.registerTraining = registerTraining;
