"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStackBarChartPadding = void 0;
var getStackBarChartPadding = function getStackBarChartPadding(data) {
    var quantity = data.length;
    switch (quantity) {
        case 1:
            return 0.8;
        case 2:
            return 0.75;
        case 3:
            return 0.7;
        case 4:
            return 0.6;
        case 5:
            return 0.5;
        case 6:
            return 0.4;
        case 7:
            return 0.35;
        case 8:
            return 0.3;
        case 9:
            return 0.25;
        case 10:
            return 0.2;
        default:
            return 0.15;
    }
};
exports.getStackBarChartPadding = getStackBarChartPadding;
