"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQuestionBaseline = exports.getQuestion = void 0;
var notFoundQuestion = {
    id: '',
    text: '',
    correctAnswers: [{
            text: ''
        }]
};
var getQuestion = function getQuestion(_ref) {
    var _ref$question = _ref.question, questionsVersion = _ref$question.questionsVersion, questionId = _ref$question.id, level = _ref$question.level, key = _ref$question.key, _ref$learningModule = _ref.learningModule, market = _ref$learningModule.market, trainingSeries = _ref$learningModule.trainingSeries, learningModuleId = _ref$learningModule.id, language = _ref.language;
    var hasKey = Boolean(key);
    var sessionStorageKey = hasKey ? "".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(language, "-hasKey") : "".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(language);
    var questions = JSON.parse(sessionStorage.getItem(sessionStorageKey));
    if (!questions && language !== 'en') {
        var sessionStorageKeyEn = hasKey ? "".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-en-hasKey") : "".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-en");
        questions = JSON.parse(sessionStorage.getItem(sessionStorageKeyEn));
    }
    if (!questions) {
        return notFoundQuestion;
    }
    var singleQuestion = hasKey ? questions[key] || Object.values(questions).find(function (_ref2) {
        var firebaseKey = _ref2.firebaseKey;
        return firebaseKey === key;
    }) : questions["".concat(learningModuleId, "_").concat(level, "_").concat(parseInt(questionId, 10) + 1)];
    return singleQuestion || notFoundQuestion;
};
exports.getQuestion = getQuestion;
var getQuestionBaseline = function getQuestionBaseline(_ref3) {
    var _ref3$question = _ref3.question, questionsVersion = _ref3$question.questionsVersion, questionId = _ref3$question.id, level = _ref3$question.level, key = _ref3$question.key, _ref3$baselineCategor = _ref3.baselineCategory, market = _ref3$baselineCategor.market, categoryId = _ref3$baselineCategor.id, trainingSeries = _ref3.trainingSeries, language = _ref3.language;
    var hasKey = Boolean(key);
    var sessionStorageKey = hasKey ? "baseline-".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(language, "-hasKey") : "baseline-".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-").concat(language);
    var questions = JSON.parse(sessionStorage.getItem(sessionStorageKey));
    if (!questions && language !== 'en') {
        var sessionStorageKeyEn = hasKey ? "baseline-".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-en-hasKey") : "baseline-".concat(market, "-").concat(trainingSeries, "-").concat(questionsVersion, "-en");
        questions = JSON.parse(sessionStorage.getItem(sessionStorageKeyEn));
    }
    if (!questions) {
        return notFoundQuestion;
    }
    var singleQuestion = hasKey ? questions[key] || Object.values(questions).find(function (_ref4) {
        var firebaseKey = _ref4.firebaseKey;
        return firebaseKey === key;
    }) : questions["".concat(categoryId, "_").concat(level || 'basic')]["question_".concat(parseInt(questionId, 10) + 1)];
    return singleQuestion || notFoundQuestion;
};
exports.getQuestionBaseline = getQuestionBaseline;
