"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldEnableStartTraining = void 0;
var shouldEnableStartTraining = function shouldEnableStartTraining(user) {
    return user.accounts.some(function (acc) {
        var _acc$accessibleQRCode;
        return (_acc$accessibleQRCode = acc.accessibleQRCodes) === null || _acc$accessibleQRCode === void 0 ? void 0 : _acc$accessibleQRCode.some(function (qrCode) {
            return !(qrCode !== null && qrCode !== void 0 && qrCode.revoked);
        });
    });
};
exports.shouldEnableStartTraining = shouldEnableStartTraining;
