"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterHiddenFacilities = void 0;
const utils_1 = require("../../../../utils");
var filterHiddenFacilities = function filterHiddenFacilities(originFacilities) {
    return originFacilities.filter(function (f) {
        return (0, utils_1.nonGoFacility)(f) && !(0, utils_1.isTestFacility)(f) && !(0, utils_1.isInvalidatedFacility)(f);
    });
};
exports.filterHiddenFacilities = filterHiddenFacilities;
