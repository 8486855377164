"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.qrCodeType = void 0;
const prop_types_1 = require("prop-types");
var config = (0, prop_types_1.shape)({
    name: prop_types_1.string.isRequired,
    trainingSeriesList: (0, prop_types_1.shape)({
        programId: prop_types_1.string,
        name: (0, prop_types_1.objectOf)(prop_types_1.string),
        version: prop_types_1.string
    }).isRequired
});
exports.qrCodeType = (0, prop_types_1.shape)({
    _id: prop_types_1.string.isRequired,
    revoked: prop_types_1.bool.isRequired,
    config: config.isRequired,
    createdAt: prop_types_1.string.isRequired
});
