"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalNavLink = exports.ItemNavLink = exports.MainNavLink = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const react_router_dom_1 = require("react-router-dom");
var navLinkCss = (0, styled_components_1.css)(["font-weight:", ";padding:", ";color:", ";overflow:hidden;", ";white-space:", ";text-decoration:none;&.is-active{color:", ";background-color:", ";border-radius:", ";text-decoration:none;}&:hover:not(.is-active),&:active{text-decoration:none;background-color:", ";border-radius:", ";}"], function (_ref) {
    var theme = _ref.theme;
    return theme.fontWeights.bold;
}, function (_ref2) {
    var theme = _ref2.theme;
    return "".concat(theme.spacing.baseDown2, " ").concat(theme.spacing.baseUnit);
}, function (_ref3) {
    var theme = _ref3.theme;
    return theme.colors.gray700;
}, function (_ref4) {
    var lineBreak = _ref4.lineBreak, theme = _ref4.theme;
    return lineBreak ? "display: flex; margin-right: ".concat(theme.spacing.baseUnit) : "";
}, function (_ref5) {
    var lineBreak = _ref5.lineBreak;
    return lineBreak ? "wrap" : "nowrap";
}, function (_ref6) {
    var theme = _ref6.theme;
    return theme.colors.white;
}, function (_ref7) {
    var theme = _ref7.theme;
    return theme.colors.blue500;
}, function (_ref8) {
    var theme = _ref8.theme;
    return "0 ".concat(theme.spacing.baseUnit, " ").concat(theme.spacing.baseUnit, " 0");
}, function (_ref9) {
    var theme = _ref9.theme;
    return theme.colors.gray300;
}, function (_ref10) {
    var theme = _ref10.theme;
    return "0 ".concat(theme.spacing.baseUnit, " ").concat(theme.spacing.baseUnit, " 0");
});
exports.MainNavLink = (0, styled_components_1.default)(react_router_dom_1.NavLink).withConfig({
    displayName: "MainNavLink",
    componentId: "gyau3i-0"
})(["", " display:block;"], navLinkCss);
exports.ItemNavLink = (0, styled_components_1.default)(react_router_dom_1.NavLink).withConfig({
    displayName: "MainNavLink__ItemNavLink",
    componentId: "gyau3i-1"
})(["", ""], navLinkCss);
exports.ExternalNavLink = styled_components_1.default.a.withConfig({
    displayName: "MainNavLink__ExternalNavLink",
    componentId: "gyau3i-2"
})(["", " display:flex;align-items:center;"], navLinkCss);
