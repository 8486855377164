"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOverviewStatsJoyrideSteps = void 0;
const constants_1 = require("../../data/constants");
const featureFlags_1 = require("../../utils/featureFlags");
const WebBasedTrainingPopup_1 = require("../WebBasedTrainingPopup");
var BaseOverviewStatsSteps = [{
        title: 'joyride:welcome.title',
        content: 'joyride:welcome.content',
        locale: {
            skip: 'joyride:skip_for_now',
            next: 'joyride:show_me_around',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        disableScrolling: true,
        placement: 'center',
        target: 'body'
    }, {
        content: 'joyride:summary.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'auto',
        target: '.joyride-overviewScrollBlock .joyride-summary',
        styles: {
            options: {
                width: 500
            }
        }
    },
    {
        content: 'joyride:facility_list.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'top',
        target: '.joyride-facilities'
    }, {
        content: 'joyride:navigation_panel.content',
        locale: {
            skip: 'joyride:skip',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 12,
        placement: 'right',
        target: '.joyride-sidebar'
    }];
var AIMOverviewStatsSteps = [{
        title: 'joyride:facility_welcome.title',
        content: 'joyride:facility_welcome.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:show_me_around',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        disableScrolling: true,
        placement: 'center',
        target: 'body'
    }, {
        content: 'joyride:access_account.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'right-end',
        target: '.joyride-userProfile'
    }, {
        content: 'joyride:start_training.content',
        locale: {
            skip: 'joyride:skip',
            next: 'joyride:next',
            last: 'joyride:finish'
        },
        disableBeacon: true,
        spotlightPadding: 5,
        placement: 'right',
        target: '.joyride-startTraining'
    }, {
        title: 'joyride:what_to_do_first',
        locale: {
            skip: 'joyride:skip',
            back: 'joyride:invite_my_organization',
            last: 'joyride:start_training.title'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'center',
        target: 'body',
        showSkip: true,
        horizontalFooter: true,
        customOnBack: function customOnBack() {
            window.location.href = '/app/account/team-management';
        },
        customOnFinish: function customOnFinish() {
            window.dispatchEvent(new CustomEvent(WebBasedTrainingPopup_1.openTrainingPopupEvent));
        },
        styles: {
            nextButton: {
                backgroundColor: 'green500'
            }
        }
    }];
var IOMOverviewStatsSteps = function IOMOverviewStatsSteps(_ref) {
    var showAccessToolkit = _ref.showAccessToolkit, userAccounts = _ref.userAccounts;
    var doFirstConfigLocale = {};
    var backHref;
    var lastHref;
    switch (true) {
        case (0, featureFlags_1.isBrandAccountOwner)(userAccounts):
        case (0, featureFlags_1.isBrandLocalManager)(userAccounts):
            doFirstConfigLocale = {
                skip: 'joyride:skip',
                back: showAccessToolkit ? 'joyride:access_toolkit' : 'joyride:finish',
                last: 'joyride:invite_my_colleagues'
            };
            backHref = '/app/resources';
            lastHref = '/app/account/team-management';
            break;
        case (0, featureFlags_1.isBrandLocalTrainer)(userAccounts):
            doFirstConfigLocale = {
                skip: 'joyride:skip',
                back: showAccessToolkit ? 'joyride:access_toolkit' : 'joyride:finish',
                last: 'joyride:invite_my_suppliers'
            };
            backHref = '/app/resources';
            lastHref = '/app/account/team-management';
            break;
        default:
            doFirstConfigLocale = {
                skip: 'joyride:skip',
                back: 'joyride:invite_my_suppliers',
                last: showAccessToolkit ? 'joyride:access_toolkit' : 'joyride:finish'
            };
            backHref = '/app/account/team-management';
            lastHref = '/app/resources';
            break;
    }
    return [{
            title: 'joyride:iom_welcome.title',
            content: 'joyride:iom_welcome.content',
            locale: {
                skip: 'joyride:skip_for_now',
                next: 'joyride:show_me_around',
                last: 'joyride:finish'
            },
            disableBeacon: true,
            disableScrolling: true,
            placement: 'center',
            target: 'body'
        }, {
            content: 'joyride:iom_toolkit.content',
            locale: {
                skip: 'joyride:skip',
                next: 'joyride:next',
                last: 'joyride:finish'
            },
            disableBeacon: true,
            spotlightPadding: 0,
            placement: 'right',
            target: '.joyride-toolkit',
            styles: {
                options: {
                    width: 500
                }
            }
        }, {
            content: 'joyride:iom_profile.content',
            locale: {
                skip: 'joyride:skip',
                next: 'joyride:next',
                last: 'joyride:finish'
            },
            disableBeacon: true,
            spotlightPadding: 5,
            placement: 'right-end',
            target: '.joyride-userProfile'
        }, {
            content: 'joyride:iom_start_training.content',
            locale: {
                skip: 'joyride:skip',
                next: 'joyride:next',
                last: 'joyride:finish'
            },
            disableBeacon: true,
            spotlightPadding: 5,
            placement: 'right',
            target: '.joyride-startTraining'
        }, {
            title: 'joyride:what_to_do_first',
            locale: doFirstConfigLocale,
            disableBeacon: true,
            spotlightPadding: 0,
            placement: 'center',
            target: 'body',
            showSkip: true,
            horizontalFooter: true,
            customOnBack: function customOnBack() {
                window.location.href = backHref;
            },
            customOnFinish: function customOnFinish() {
                if (showAccessToolkit) {
                    window.location.href = lastHref;
                }
            }
        }];
};
var AuroraOverviewStatsSteps = [{
        title: 'joyride:aurora_welcome.title',
        content: 'joyride:aurora_welcome.content',
        locale: {
            skip: 'global:close',
            last: 'joyride:start_training.title'
        },
        disableBeacon: true,
        spotlightPadding: 0,
        placement: 'center',
        target: 'body',
        showSkip: true,
        horizontalFooter: true,
        customOnFinish: function customOnFinish() {
            window.dispatchEvent(new CustomEvent(WebBasedTrainingPopup_1.openTrainingPopupEvent));
        },
        styles: {
            nextButton: {
                backgroundColor: 'green500'
            }
        }
    }];
var getOverviewStatsJoyrideSteps = function getOverviewStatsJoyrideSteps(_ref2) {
    var signupFlow = _ref2.signupFlow, features = _ref2.features, userAccounts = _ref2.userAccounts;
    switch (signupFlow) {
        case constants_1.SignUpFlows.Tatwin:
        case constants_1.SignUpFlows.AIM:
        case constants_1.SignUpFlows.AIM_1:
        case constants_1.SignUpFlows.HRDD:
        case constants_1.SignUpFlows.ULESR:
        case constants_1.SignUpFlows.ARTEMIS:
        case constants_1.SignUpFlows.VF:
        case constants_1.SignUpFlows.RA:
            return AIMOverviewStatsSteps;
        case constants_1.SignUpFlows.IOM:
            {
                var showAccessToolkit = features.includes(constants_1.Features.ManageResources);
                return IOMOverviewStatsSteps({
                    showAccessToolkit: showAccessToolkit,
                    userAccounts: userAccounts
                });
            }
        case constants_1.SignUpFlows.AuroraCLUS:
        case constants_1.SignUpFlows.AuroraGSCS:
        case constants_1.SignUpFlows.AuroraWE:
            return AuroraOverviewStatsSteps;
        default:
            return BaseOverviewStatsSteps;
    }
};
exports.getOverviewStatsJoyrideSteps = getOverviewStatsJoyrideSteps;
