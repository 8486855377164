"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProgressColors = exports.GrowthStatus = exports.PerformanceStatus = void 0;
exports.PerformanceStatus = function (PerformanceStatus) {
    PerformanceStatus["High"] = "high";
    PerformanceStatus["Medium"] = "medium";
    PerformanceStatus["Low"] = "low";
    return PerformanceStatus;
}({});
exports.GrowthStatus = function (GrowthStatus) {
    GrowthStatus["Negative"] = "negative";
    GrowthStatus["Positive"] = "positive";
    return GrowthStatus;
}({});
var getProgressColors = function getProgressColors(status) {
    switch (status) {
        case exports.PerformanceStatus.High:
            return {
                textColor: 'green500',
                backgroundColor: 'green100'
            };
        case exports.PerformanceStatus.Medium:
            return {
                textColor: 'yellow700',
                backgroundColor: 'yellow300'
            };
        case exports.PerformanceStatus.Low:
            return {
                textColor: 'orange',
                backgroundColor: 'orange100'
            };
        default:
            return {
                textColor: 'orange',
                backgroundColor: 'orange100'
            };
    }
};
exports.getProgressColors = getProgressColors;
