"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HIDED_LEARNING_MODULES = exports.TOPIC_THRESHOLD = exports.LegendKey = exports.CARD_PADDING = exports.REPORT_FOOTER_HEIGHT = exports.ONE_THIRD_PAGE_WIDTH = exports.HALF_PAGE_HEIGHT = exports.HALF_PAGE_WIDTH = void 0;
exports.HALF_PAGE_WIDTH = 503;
exports.HALF_PAGE_HEIGHT = 225;
exports.ONE_THIRD_PAGE_WIDTH = 320;
exports.REPORT_FOOTER_HEIGHT = 50;
exports.CARD_PADDING = 10;
exports.LegendKey = function (LegendKey) {
    LegendKey["GENDER"] = "gender";
    LegendKey["HOME_COUNTRY"] = "home_country";
    LegendKey["DOMESTIC_MIGRANT"] = "domestic_migrant";
    LegendKey["PERFORMANCE"] = "performance";
    LegendKey["CONCENTRATION"] = "concentration";
    LegendKey["FEEDBACK_QUESTIONS"] = "feedback_questions";
    LegendKey["KNOWLEDGE_GAIN"] = "knowledge_gain";
    LegendKey["COMPLETION_RATE"] = "completion_rate";
    LegendKey["AVERAGE_TRAINING_TIME"] = "average_training_time";
    LegendKey["TOP_BOTTOM"] = "TOP_BOTTOM";
    return LegendKey;
}({});
exports.TOPIC_THRESHOLD = 10;
exports.HIDED_LEARNING_MODULES = ['disease_control_prevention'];
