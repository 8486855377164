"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFiltersPipeline = void 0;
const react_1 = require("react");
const useSorting_1 = require("./useSorting");
const useSwitchFilter_1 = require("./useSwitchFilter");
const useTextSearch_1 = require("./useTextSearch");
var useFiltersPipeline = function useFiltersPipeline(_ref) {
    var items = _ref.items, sortingConfigs = _ref.sortingConfigs, searchFilter = _ref.searchFilter, switchFilter = _ref.switchFilter, filter = _ref.filter;
    var _useTextSearch = (0, useTextSearch_1.useTextSearch)(items, searchFilter), inputText = _useTextSearch.inputText, setInputText = _useTextSearch.setInputText, filteredItems = _useTextSearch.filteredItems;
    var _useSwitchFilter = (0, useSwitchFilter_1.useSwitchFilter)({
        items: filteredItems,
        filter: switchFilter
    }), isSwitchOn = _useSwitchFilter.isSwitchOn, setIsSwitchOn = _useSwitchFilter.setIsSwitchOn, switchFilteredList = _useSwitchFilter.switchFilteredList;
    var _useSorting = (0, useSorting_1.useSorting)({
        listForSorting: switchFilteredList,
        configs: sortingConfigs
    }), setSelectedSortConfig = _useSorting.setSelectedSortConfig, sortConfigs = _useSorting.sortConfigs, selectedSortConfig = _useSorting.selectedSortConfig, sortedList = _useSorting.sortedList;
    var filteredList = (0, react_1.useMemo)(function () {
        return sortedList.filter(filter);
    }, [sortedList, filter]);
    return {
        inputText: inputText,
        setInputText: setInputText,
        setSelectedSortConfig: setSelectedSortConfig,
        sortConfigs: sortConfigs,
        selectedSortConfig: selectedSortConfig,
        setIsSwitchOn: setIsSwitchOn,
        isSwitchOn: isSwitchOn,
        result: filteredList
    };
};
exports.useFiltersPipeline = useFiltersPipeline;
