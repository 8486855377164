"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStripeCustomer = exports.stripeSetDefaultPaymentMethod = exports.stripeDeletePaymentMethod = exports.stripeCreatePaymentMethod = exports.stripeUpdatePaymentMethod = exports.stripListPaymentMethods = exports.stripeCancelCustomerSubscription = exports.stripeListCustomerSubscriptions = exports.stripeListTransactions = exports.stripeCreateSubscription = void 0;
const utils_1 = require("../utils");
var stripeCreateSubscription = function stripeCreateSubscription(payload) {
    return (0, utils_1.post)({
        endpoint: 'payments/subscription',
        payload: payload,
        withAuth: false
    });
};
exports.stripeCreateSubscription = stripeCreateSubscription;
var stripeListTransactions = function stripeListTransactions(customerId) {
    return (0, utils_1.get)({
        endpoint: "payments/transactions?customerId=".concat(customerId)
    });
};
exports.stripeListTransactions = stripeListTransactions;
var stripeListCustomerSubscriptions = function stripeListCustomerSubscriptions(customerId) {
    return (0, utils_1.get)({
        endpoint: "payments/subscription?customerId=".concat(customerId)
    });
};
exports.stripeListCustomerSubscriptions = stripeListCustomerSubscriptions;
var stripeCancelCustomerSubscription = function stripeCancelCustomerSubscription(payload) {
    return (0, utils_1.post)({
        endpoint: 'payments/subscription/cancel',
        payload: payload
    });
};
exports.stripeCancelCustomerSubscription = stripeCancelCustomerSubscription;
var stripListPaymentMethods = function stripListPaymentMethods(customerId) {
    return (0, utils_1.get)({
        endpoint: "payments/payment-methods?customerId=".concat(customerId)
    });
};
exports.stripListPaymentMethods = stripListPaymentMethods;
var stripeUpdatePaymentMethod = function stripeUpdatePaymentMethod(payload) {
    return (0, utils_1.post)({
        endpoint: 'payments/payment-methods/update',
        payload: payload
    });
};
exports.stripeUpdatePaymentMethod = stripeUpdatePaymentMethod;
var stripeCreatePaymentMethod = function stripeCreatePaymentMethod(payload) {
    return (0, utils_1.post)({
        endpoint: 'payments/payment-methods',
        payload: payload
    });
};
exports.stripeCreatePaymentMethod = stripeCreatePaymentMethod;
var stripeDeletePaymentMethod = function stripeDeletePaymentMethod(paymentMethodId) {
    return (0, utils_1.remove)({
        endpoint: "payments/payment-methods/".concat(paymentMethodId)
    });
};
exports.stripeDeletePaymentMethod = stripeDeletePaymentMethod;
var stripeSetDefaultPaymentMethod = function stripeSetDefaultPaymentMethod(payload) {
    return (0, utils_1.post)({
        endpoint: 'payments/payment-methods/set-default',
        payload: payload
    });
};
exports.stripeSetDefaultPaymentMethod = stripeSetDefaultPaymentMethod;
var getStripeCustomer = function getStripeCustomer() {
    return (0, utils_1.get)({
        endpoint: "payments/get-customer"
    });
};
exports.getStripeCustomer = getStripeCustomer;
