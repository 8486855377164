"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChartColors = void 0;
var ChartColorStrings = ['blue300', 'blue500', 'blue600', 'gray300', 'blue900'];
var getChartColors = function getChartColors(index) {
    var excluded = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var colors = ChartColorStrings.filter(function (c) {
        return excluded.indexOf(c) === -1;
    });
    return colors[index % colors.length];
};
exports.getChartColors = getChartColors;
