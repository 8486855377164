"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trainingPlanType = exports.baselineTrainingSeriesStatsType = exports.trainingSeriesStatsType = exports.byQuestionType = exports.sessionDatesType = exports.knowledgeChangeType = exports.performanceType = exports.byJobLevelType = exports.byGenderType = exports.factoryType = void 0;
const prop_types_1 = require("prop-types");
exports.factoryType = (0, prop_types_1.shape)({
    name: prop_types_1.string.isRequired,
    market: (0, prop_types_1.shape)({
        name: prop_types_1.string
    }).isRequired
});
exports.byGenderType = (0, prop_types_1.shape)({
    male: prop_types_1.number,
    female: prop_types_1.number,
    unknown: prop_types_1.number,
    other: prop_types_1.number,
    total: prop_types_1.number
});
exports.byJobLevelType = (0, prop_types_1.shape)({
    manager: prop_types_1.number.isRequired,
    midManager: prop_types_1.number.isRequired,
    worker: prop_types_1.number.isRequired,
    trainer: prop_types_1.number.isRequired,
    total: prop_types_1.number.isRequired
});
exports.performanceType = (0, prop_types_1.shape)({
    answersCount: (0, prop_types_1.shape)({
        total: prop_types_1.number.isRequired
    }).isRequired,
    correctAnswersCount: (0, prop_types_1.shape)({
        total: prop_types_1.number.isRequired
    }).isRequired
});
exports.knowledgeChangeType = (0, prop_types_1.shape)({
    baselinePerformance: exports.performanceType,
    baselineBasedTrainingPerformance: exports.performanceType
});
exports.sessionDatesType = (0, prop_types_1.shape)({
    first: (0, prop_types_1.oneOfType)([prop_types_1.string, (0, prop_types_1.instanceOf)(Date)]).isRequired,
    last: (0, prop_types_1.oneOfType)([prop_types_1.string, (0, prop_types_1.instanceOf)(Date)]).isRequired
});
exports.byQuestionType = (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
    id: prop_types_1.string,
    level: prop_types_1.string,
    questionsVersion: prop_types_1.string,
    type: prop_types_1.string,
    performance: exports.performanceType
}));
exports.trainingSeriesStatsType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    byGenderUnique: exports.byGenderType,
    byJobLevelUnique: exports.byJobLevelType,
    learningModules: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
        id: prop_types_1.string.isRequired,
        level: prop_types_1.string,
        market: prop_types_1.string.isRequired,
        trainingSeries: prop_types_1.string.isRequired,
        sessionDates: exports.sessionDatesType,
        byJobLevel: (0, prop_types_1.shape)({
            manager: prop_types_1.number,
            midManager: prop_types_1.number,
            worker: prop_types_1.number,
            trainer: prop_types_1.number,
            total: prop_types_1.number
        }),
        byJobLevelUnique: (0, prop_types_1.shape)({
            manager: prop_types_1.number,
            midManager: prop_types_1.number,
            worker: prop_types_1.number,
            trainer: prop_types_1.number,
            total: prop_types_1.number
        }),
        performance: (0, prop_types_1.shape)({
            answersCount: (0, prop_types_1.shape)({
                total: prop_types_1.number,
                byJobLevel: (0, prop_types_1.shape)({
                    total: prop_types_1.number
                })
            }),
            correctAnswersCount: (0, prop_types_1.shape)({
                total: prop_types_1.number,
                byJobLevel: (0, prop_types_1.shape)({
                    total: prop_types_1.number
                })
            })
        }),
        byQuestion: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
            id: prop_types_1.string.isRequired,
            key: prop_types_1.string,
            questionsVersion: prop_types_1.string.isRequired,
            level: prop_types_1.string,
            performance: exports.performanceType
        }))
    })).isRequired
});
exports.baselineTrainingSeriesStatsType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    sessionsCount: prop_types_1.number.isRequired,
    levels: (0, prop_types_1.arrayOf)(prop_types_1.string).isRequired,
    learningModules: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
        id: prop_types_1.string.isRequired,
        market: prop_types_1.string.isRequired,
        trainingSeries: prop_types_1.string.isRequired,
        categories: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
            id: prop_types_1.string.isRequired,
            market: prop_types_1.string.isRequired,
            sessionsCount: prop_types_1.number.isRequired,
            trainingSeries: prop_types_1.string.isRequired,
            learningModule: prop_types_1.string.isRequired,
            level: prop_types_1.string.isRequired,
            playersCount: prop_types_1.number.isRequired,
            performance: exports.performanceType,
            byQuestion: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
                id: prop_types_1.string.isRequired,
                key: prop_types_1.string.isRequired,
                questionsVersion: prop_types_1.string.isRequired,
                level: prop_types_1.string,
                performance: exports.performanceType
            }))
        }))
    })).isRequired
});
exports.trainingPlanType = (0, prop_types_1.shape)({
    _id: prop_types_1.string.isRequired,
    startDate: prop_types_1.string.isRequired,
    endDate: prop_types_1.string.isRequired,
    trainingSeriesId: prop_types_1.string.isRequired,
    excludedDates: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
        fromDate: prop_types_1.string.isRequired,
        untilDate: prop_types_1.string.isRequired
    }).isRequired),
    employeesToTrain: prop_types_1.number.isRequired,
    level: prop_types_1.string.isRequired,
    requiredSessions: (0, prop_types_1.shape)({
        total: prop_types_1.number
    }),
    conductedSessions: (0, prop_types_1.shape)({
        total: prop_types_1.number
    }),
    baselineRequiredSessions: prop_types_1.number.isRequired,
    endlineRequiredSessions: prop_types_1.number.isRequired,
    learningModules: (0, prop_types_1.arrayOf)(prop_types_1.string)
});
