"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryParams = useQueryParams;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
function useQueryParams() {
    var _useLocation = (0, react_router_dom_1.useLocation)(), search = _useLocation.search;
    return (0, react_1.useMemo)(function () {
        return new URLSearchParams(search);
    }, [search]);
}
