"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trainingSeriesType = exports.learningModuleType = exports.trainingDataType = exports.baselineDataType = exports.baselineCategoryType = void 0;
const prop_types_1 = require("prop-types");
const types_1 = require("../../data/types");
exports.baselineCategoryType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    trainingSeries: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    level: prop_types_1.string,
    performance: types_1.performanceType,
    byQuestion: types_1.byQuestionType,
    sessionsCount: prop_types_1.number.isRequired,
    playersCount: prop_types_1.number.isRequired
});
exports.baselineDataType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    learningModules: (0, prop_types_1.shape)({
        categories: (0, prop_types_1.arrayOf)(exports.baselineCategoryType).isRequired,
        id: prop_types_1.string.isRequired,
        market: prop_types_1.string.isRequired,
        trainingSeries: prop_types_1.string.isRequired
    }).isRequired,
    levels: (0, prop_types_1.arrayOf)(prop_types_1.string).isRequired,
    sessionsCount: prop_types_1.number.isRequired
});
exports.trainingDataType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    learningModules: (0, prop_types_1.arrayOf)((0, prop_types_1.shape)({
        id: prop_types_1.string.isRequired,
        level: prop_types_1.string.isRequired,
        market: prop_types_1.string.isRequired,
        trainingSeries: prop_types_1.string.isRequired,
        byJobLevel: types_1.byJobLevelType,
        byJobLevelUnique: types_1.byJobLevelType,
        byQuestion: types_1.byQuestionType,
        performance: types_1.performanceType,
        sessionDates: types_1.sessionDatesType
    }).isRequired).isRequired
});
exports.learningModuleType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    trainingSeries: prop_types_1.string.isRequired,
    baseline: exports.baselineCategoryType,
    training: (0, prop_types_1.shape)({
        byQuestion: types_1.byQuestionType,
        byJobLevelUnique: types_1.byJobLevelType,
        performance: types_1.performanceType,
        knowledgeChange: {
            baselinePerformance: types_1.performanceType,
            baselineBasedTrainingPerformance: types_1.performanceType
        }
    }).isRequired
});
exports.trainingSeriesType = (0, prop_types_1.shape)({
    id: prop_types_1.string.isRequired,
    market: prop_types_1.string.isRequired,
    baselineCategories: (0, prop_types_1.arrayOf)(exports.baselineCategoryType),
    baselineData: exports.baselineDataType,
    trainingData: exports.trainingDataType
});
