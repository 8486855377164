"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SPACING = exports.FORM_MAX_WIDTH = exports.QUIZRR_SUPPORT_SITE_URL = exports.COOKIE_NAME_QUIZRR_CONSENT = exports.TABLET_MAX_WIDTH = exports.MOBILE_MAX_WIDTH = exports.LOGO_HEIGHT = exports.PAGE_HEADER_HEIGHT = exports.HEADER_BAR_HEIGHT = exports.CONTENT_MAX_WIDTH = exports.MAX_BASELINES = exports.MIN_BASELINES = exports.BASELINE_CATEGORIES_ORDERED = exports.PIE_CHART_DECIMALS = void 0;
exports.PIE_CHART_DECIMALS = 0;
exports.BASELINE_CATEGORIES_ORDERED = ['baseline', 'follow-up'];
exports.MIN_BASELINES = 1;
exports.MAX_BASELINES = 100;
exports.CONTENT_MAX_WIDTH = 1400;
exports.HEADER_BAR_HEIGHT = 50;
exports.PAGE_HEADER_HEIGHT = 100;
exports.LOGO_HEIGHT = 25;
exports.MOBILE_MAX_WIDTH = 599;
exports.TABLET_MAX_WIDTH = 1022;
exports.COOKIE_NAME_QUIZRR_CONSENT = 'quizrr-consent';
exports.QUIZRR_SUPPORT_SITE_URL = 'https://support.quizrr.se/en/support/home';
exports.FORM_MAX_WIDTH = 650;
exports.SPACING = {
    auto: 'auto',
    0: '0',
    baseDown4: '0',
    baseDown3: '4px',
    baseDown2: '8px',
    baseDown1: '12px',
    baseUnit: '16px',
    baseUp1: '20px',
    baseUp2: '24px',
    baseUp3: '28px',
    baseUp4: '32px',
    baseUp5: '36px',
    baseUp6: '40px',
    baseUp7: '44px',
    baseUp8: '48px',
    baseUp9: '52px',
    baseUp10: '56px',
    baseUp11: '60px',
    baseUp12: '64px',
    baseUp13: '68px',
    baseUp14: '72px',
    baseUp15: '76px',
    baseUp16: '80px',
    baseUp17: '84px',
    baseUp18: '88px',
    baseUp19: '92px',
    baseUp20: '96px'
};
__exportStar(require("./learningModules"), exports);
__exportStar(require("./filter"), exports);
__exportStar(require("./joyride"), exports);
__exportStar(require("./date"), exports);
__exportStar(require("./signup"), exports);
__exportStar(require("./trainingParticipationTypes"), exports);
__exportStar(require("./supplier"), exports);
__exportStar(require("./color"), exports);
