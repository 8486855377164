"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldPageHideCookieConsent = void 0;
var shouldPageHideCookieConsent = function shouldPageHideCookieConsent(pageUrl) {
    var pages = [{
            name: 'certificate',
            regex: new RegExp(/^\/certificate/)
        }, {
            name: 'generate-report',
            regex: new RegExp(/^\/generate-report/)
        }];
    return pages.some(function (page) {
        return page.regex.test(pageUrl);
    });
};
exports.shouldPageHideCookieConsent = shouldPageHideCookieConsent;
