"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReferenceMarker = void 0;
var getReferenceMarker = function getReferenceMarker(_ref) {
    var list = _ref.list, id = _ref.id;
    return list.findIndex(function (element) {
        return element.id === id;
    }) + 1;
};
exports.getReferenceMarker = getReferenceMarker;
