"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequired = void 0;
const react_i18next_1 = require("react-i18next");
var useRequired = function useRequired() {
    var _useTranslation = (0, react_i18next_1.useTranslation)(), t = _useTranslation.t;
    return {
        value: true,
        message: t('training_plans:required')
    };
};
exports.useRequired = useRequired;
