"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCustomViewFilters = exports.getCustomViewFilters = void 0;
var CUSTOM_VIEW_FILTERS_KEY = 'customViewFilters';
var getCustomViewFilters = function getCustomViewFilters() {
    return localStorage.getItem(CUSTOM_VIEW_FILTERS_KEY) ? JSON.parse(localStorage.getItem(CUSTOM_VIEW_FILTERS_KEY)) : [];
};
exports.getCustomViewFilters = getCustomViewFilters;
var setCustomViewFilters = function setCustomViewFilters(data) {
    localStorage.setItem(CUSTOM_VIEW_FILTERS_KEY, JSON.stringify(data));
};
exports.setCustomViewFilters = setCustomViewFilters;
