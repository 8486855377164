"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("../../../../../utils");
const models_1 = require("../models");
const team_1 = require("../../../../../data/constants/team");
var createBecomingSupplierRequest = function createBecomingSupplierRequest(_ref) {
    var email = _ref.email, invitationCode = _ref.invitationCode, facilityId = _ref.facilityId;
    return (0, utils_1.post)({
        endpoint: "accounts-facilities",
        payload: {
            email: email,
            type: 'supplier',
            status: team_1.MemberTrainingStatus.REQUEST_PENDING,
            invitationCode: invitationCode,
            facility: facilityId,
            strategy: models_1.InviteSupplierStrategy.LinkSupplierByInvitationCode
        }
    });
};
exports.default = createBecomingSupplierRequest;
