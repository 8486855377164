"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
var getButtonProps = function getButtonProps() {
    var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    switch (type) {
        case constants_1.ButtonStatus.LOADING:
            return {
                bgColor: 'gray400',
                content: content,
                icon: 'spinner',
                disabled: true
            };
        case constants_1.ButtonStatus.SUCCESS:
            return {
                bgColor: 'green500',
                content: content,
                icon: 'check-circle',
                disabled: true
            };
        case constants_1.ButtonStatus.FAIL:
            return {
                bgColor: 'warning',
                content: content,
                icon: 'exclamation-circle',
                disabled: true
            };
        default:
            return {
                bgColor: '',
                content: content,
                icon: ''
            };
    }
};
exports.default = getButtonProps;
