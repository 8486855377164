"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../../../data/constants");
const utils_1 = require("../../../../../utils");
var revokeSupplier = function revokeSupplier(relationId) {
    return (0, utils_1.patch)({
        endpoint: "accounts-facilities/".concat(relationId),
        payload: {
            status: constants_1.SupplierStatus.REVOKED
        }
    });
};
exports.default = revokeSupplier;
