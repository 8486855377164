"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getManageableAccounts = void 0;
const enums_1 = require("../../../../../data/enums");
const featureFlags_1 = require("../../../../../utils/featureFlags");
var getManageableAccounts = function getManageableAccounts(accounts) {
    var userOwnedAccounts = accounts.filter(function (acc) {
        return [enums_1.AccountRole.ADMIN, enums_1.AccountRole.OWNER].includes(acc.role);
    });
    if ((0, featureFlags_1.isBrandAccountOwner)(accounts)) {
        return userOwnedAccounts.filter(function (acc) {
            return acc.accountType === enums_1.AccountType.BRAND;
        });
    }
    return userOwnedAccounts;
};
exports.getManageableAccounts = getManageableAccounts;
