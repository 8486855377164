"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormOptions = void 0;
const queryBrands_1 = require("../../../../../apis/queryBrands");
const queryDiscountCampaigns_1 = require("../apis/queryDiscountCampaigns");
const queryUsers_1 = require("../apis/queryUsers");
var useFormOptions = function useFormOptions() {
    var _queryBrands = (0, queryBrands_1.queryBrands)(), brandQueryLoading = _queryBrands.loading, brands = _queryBrands.brands;
    var _queryDynamicDiscount = (0, queryDiscountCampaigns_1.queryDynamicDiscountCampaigns)(), discountCampaignLoading = _queryDynamicDiscount.loading, dynamicDiscountCampaigns = _queryDynamicDiscount.dynamicDiscountCampaigns;
    var _queryUsers = (0, queryUsers_1.queryUsers)(), userQueryDataLoading = _queryUsers.loading, users = _queryUsers.users;
    var loading = brandQueryLoading || !brands || discountCampaignLoading || !dynamicDiscountCampaigns || userQueryDataLoading || !users;
    return {
        loading: loading,
        options: loading ? undefined : {
            brands: brands,
            dynamicDiscountCampaigns: dynamicDiscountCampaigns,
            users: users
        }
    };
};
exports.useFormOptions = useFormOptions;
